import React, { useEffect, useState } from "react"
import axios from "axios"
import SEO from "components/seo"
import Layout from "../components/layout"
import SubBanner from "../components/SubBanner"
import styled from "styled-components"
import tw from "tailwind.macro"
import Link from "../components/Link"
import Breadcrumb from "../components/Breadcrumb"
import ListLoading from "../components/ListLoading"

const CategoryItem = styled.div`
  ${tw`w-full mb-48 sm:px-30 md:w-1/2 lg:px-18 lg:w-1/3 xl:w-1/4`}

  .product-item-inner {
    ${tw`mx-auto flex flex-col h-full`}
    max-width: 245px;

    @media (min-width: 1024px) {
      max-width: 345px;
    }
  }

  .btn-wrap {
    ${tw`flex items-end`}
    flex-grow: 1;
  }

  img {
    min-height: 245px;
    max-height: 325px;
    border-radius: 60px 0;
    ${tw`object-cover`}

    @media (min-width: 1280px) {
      min-height: 325px;
    }
  }

  h4 {
    ${tw`font-fb text-xl text-black-dark leading-7 mt-16`}
  }

  p {
    ${tw`leading-9 font-medium mt-7`}
  }

  table {
    ${tw`w-full table-fixed mt-26`}

    thead {
      ${tw`text-left`}
    }

    th {
      ${tw`font-fb`}
    }

    td {
      ${tw`font-normal`}
    }
  }
`

const SubCategoryListing = ({ location, pageContext }) => {
  const [result, setResult] = useState(null)

  useEffect(() => {
    const grapqlURL = `${process.env.SOURCE_URL}/graphql`

    const fetchData = async () => {
      const { data: subCategoryData } = await axios({
        url: grapqlURL,
        method: "post",
        data: {
          query: `
						query {
							subCategory: productCategory(id: "${pageContext.id}") {
								name
								slug
								description
								acfCategory {
									categoryImage {
										sourceUrl
									}
								}
								parent {
									node {
										id
										name
										slug
										uri
									}
								}
								products(first: 1000) {
									nodes {
										slug
										id
										title
										acfProducts {
											description: productDescription
											excerpt: productSmallDescription
											options: productOption {
												package: productPackage
												size: productSize
												weight: productWeight
												featured: productFeatured
											}
											images: productImages {
												sourceUrl(size: MEDIUM)
											}
										}
									}
								}
							}
						}
					`,
        },
      })
      setResult(subCategoryData.data)
    }
    fetchData()
  }, [pageContext.id])

  const featuredProducts = product => {
    return (
      product?.acfProducts?.options?.filter(option => option.featured).length >
      0
    )
  }

  const breadcrumbsData = [
    {
      title: "All Products",
      url: "/products",
      visibleInMobile: false,
    },
    {
      title: result?.subCategory?.parent?.node?.name,
      url: `/products/${result?.subCategory?.parent?.node?.slug}`,
      visibleInMobile: true,
    },
    {
      title: result?.subCategory?.name,
      url: "",
      visibleInMobile: false,
    },
  ]

  return (
    <Layout path={location.pathname}>
      <SEO
        data={{
          title: `Category - ${result?.subCategory?.parent?.node?.name} - ${result?.subCategory?.name}`,
          metaDesc: result?.subCategory?.description,
        }}
      />
      <SubBanner mainTitle={result?.subCategory?.name} />
      <Breadcrumb pages={breadcrumbsData} noViewAll={true} />
      <div className="max-w-xl mx-auto px-18 md:px-40 lg:px-60 xxl:px-0 section-gap-sm xl:section-gap-xl">
        <div className="flex flex-wrap md:-mx-30 lg:-mx-18 -mb-48">
          {result ? (
            <>
              {result?.subCategory?.products?.nodes.map(product => (
                <CategoryItem key={product.id}>
                  <div className="product-item-inner">
                    <Link
                      to={`/products/${result?.subCategory?.parent?.node?.slug}/${result?.subCategory?.slug}/${product.slug}`}
                    >
                      <img
                        src={product?.acfProducts?.images[0]?.sourceUrl}
                        alt={product?.title}
                      />
                    </Link>
                    <Link
                      to={`/products/${result?.subCategory?.parent?.node?.slug}/${result?.subCategory?.slug}/${product.slug}`}
                    >
                      <h4>{product?.title}</h4>
                    </Link>
                    {/* <p
                      dangerouslySetInnerHTML={{
                        __html: product?.acfProducts?.excerpt,
                      }}
                    ></p> */}
                    {featuredProducts(product) && (
                      <table>
                        <thead>
                          <tr>
                            <th>Weight</th>
                            <th>Size</th>
                            <th>Package</th>
                          </tr>
                        </thead>
                        <tbody>
                          {product?.acfProducts?.options.map(
                            option =>
                              option.featured && (
                                <tr key={option.size}>
                                  <td>{option.weight}</td>
                                  <td>{option.size}</td>
                                  <td>{option.package}</td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    )}
                    <div className="btn-wrap">
                      <Link
                        className="btn-secondary btn-secondary-rounded-sm xl:btn-secondary-rounded-xl btn-sm mt-16 xl:mt-16"
                        to={`/products/${result?.subCategory?.parent?.node?.slug}/${result?.subCategory?.slug}/${product.slug}`}
                      >
                        View Product
                      </Link>
                    </div>
                  </div>
                </CategoryItem>
              ))}
            </>
          ) : (
            <ListLoading />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default SubCategoryListing
